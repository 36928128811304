<template>
  <v-col>
    <h3>1. Descargar Plantilla</h3>
    <p>
      Para poder actualizar correctamente los datos, el formato del excel debe ser igual al de la
      pantilla.
    </p>
    <div class="text-center">
      <v-btn color="info" text @click.stop="downloadTemplate" :loading="downloadingTemplate">
        <v-icon size="18" class="mr-1">mdi-download</v-icon>
        Descargar plantilla
      </v-btn>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    downloadImportExcelTemplateActions: { type: Function, required: true },
  },
  data: () => ({
    downloadingTemplate: null,
  }),
  methods: {
    async downloadTemplate() {
      this.downloadingTemplate = true;
      try {
        const response = await this.downloadImportExcelTemplateActions();
        const random = Date.now().toString();
        const name =
          this.$route.name +
          "_" +
          this.$moment().format("DDMMYY") +
          "_" +
          random.slice(-3) +
          ".xlsx";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch {
        this.$notifyError(undefined, "Ha habido un error en la descarga de la plantilla.");
      } finally {
        this.downloadingTemplate = false;
      }
    },
  },
};
</script>

<style scoped></style>
